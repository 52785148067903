<template>
  <div
    class="fixed top-12 left-0 right-0 bottom-0 w-full overflow-y-auto overflow-x-hidden"
  >
    <drawer />
    <app-header />
    <slot />
  </div>
</template>

<script>
import Drawer from '@/_components/Drawer.vue'
import AppHeader from '@/_components/AppHeader.vue'
export default {
  components: { Drawer, AppHeader },
  name: 'FullPage'
}
</script>
