<template>
  <div data-app>
    <full-page>
      <tool-bar>
        <template v-slot:title>
          <p class="text-lg mr-5">{{ $route.meta.name }}</p>
        </template>
        <template v-slot:actions>
          <v-select
            v-model="selectedStatus"
            :items="statusList"
            item-text="title"
            item-value="status"
            return-object
            class="max-w-xs"
            @change="updateTableList"
          ></v-select>
        </template>
        <template v-slot:actionButtons>
          <router-link to="/properties/create">
            <button class="btn-tools btn-with-icons">
              <i class="mdi mdi-plus " />
              Aggiungi
            </button>
          </router-link>
          <input type="checkbox" class="hidden" id="create-btn" />
          <label
            v-if="auth"
            for="delete-btn"
            class="relative  py-2  btn-tools-with-icon-disabled flex space-x-2 whitespace-nowrap"
          >
            <i class="mdi mdi-delete "></i>

            <p class="">Elimina</p>
          </label>
          <input type="checkbox" class="hidden" id="delete-btn" />
        </template>
      </tool-bar>

      <div
        class="fixed left-0 right-0 top-24 bottom-0 overscroll-y-auto overflow-y-auto"
      >
        <table-data
          :data="structures"
          :options="tableOpts"
          :key="updatingKey"
          @rowSelected="goToEdit"
          sortable
          defaultSortField="title"
          defaultSortDir="desc"
        >
          <template v-slot:th-column-value="props">
            <span v-if="props.label == 'id'" class="hidden"></span>
            <span v-else>{{ props.label }}</span>
          </template>

          <template v-slot:column-value="props">
            <span v-if="props.field == 'id'" class="hidden"></span>
            <span v-else class="cursor-pointer">
              {{ props.column }}
            </span>
          </template>

          <template v-slot:actions="props">
            <span>
              <button
                class="focus:outline-none mx-2"
                @click="$router.push(`/properties/${props.row.id}`)"
              >
                <i
                  class="mdi mdi-pencil w-3 h-3 text-gray-600 hover:text-blue-500"
                />
              </button>
              <button
                v-if="auth"
                class="focus:outline-none mx-2"
                @click.stop="deleteStructure(props.row)"
              >
                <i
                  class="mdi mdi-trash-can w-3 h-3 text-gray-600 hover:text-red-500"
                />
              </button>
            </span>
          </template>
        </table-data>
      </div>
    </full-page>
  </div>
</template>

<script>
import FullPage from '@/_templates/FullPage.vue'
import ToolBar from '@/_components/ToolBar.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Properties',
  components: {
    FullPage,
    ToolBar,
    TableData: () => import('@/_components/TableData.vue')
    // PropertyCard,
    // PropertyCardList
  },
  props: {
    view: {
      type: String,
      default: 'card'
    }
  },
  data: function() {
    return {
      tableOpts: {
        keys: ['id', 'title', 'owner', 'type', 'address'],
        sortableKeys: ['title', 'owner', 'type', 'address']
      },
      selectedStatus: { title: 'Published', status: 'published' },
      statusList: [
        { title: 'New', status: 'new' },
        { title: 'Published', status: 'published' },
        { title: 'Unpublished', status: 'unpublished' },
        { title: 'Archived', status: 'archived' }
      ],
      ASSETS_DIR: process.env.ASSETS_DIR,
      nextToken: null,
      showPagination: true,
      updatingKey: 0
    }
  },
  methods: {
    // ...mapActions("structures", ["list"]),
    // queryStructures: async function() {
    //     this.$store.dispatch('structures/query');
    // },
    async updateTableList() {
      await this.listStructures({ status: this.selectedStatus.status })
    },
    showDelete(e) {
      console.log('Show delete ', e)
      this.deleteId = e
      this.confirmDelete = true
    },
    listStructures: async function(filter = {}) {
      this.$store.dispatch('structures/list', filter).then(data => {
        this.updatingKey = this.updatingKey + 1
        this.nextToken = data.length > 0 ? data.nextToken : null
        this.showPagination = data.nextToken != null
      })
    },
    deleteStructure: async function(s) {
      console.log('Delete Structure', s)
      if (!s) {
        this.deleteId = null
        this.confirmDelete = false
        return
      }
      await this.$store.dispatch('structures/delete', { id: s.id })
      await this.$store.dispatch('structures/list', { status: 'published' })
      this.confirmDelete = false
    },
    goToEdit(id) {
      this.$router.push(`/properties/${id}`)
    }
  },
  computed: {
    ...mapGetters({ structures: 'structures/getData' }),
    ...mapGetters({ auth: 'auth/isAdmin' })
  },
  async created() {
    //this.updateStructure()
    //this.createStructure();

    await this.listStructures({ status: this.selectedStatus.status })
    //this.deleteStructure();
  }
}
</script>
<style lang="postcss" scoped>
h1 {
  @apply text-xl font-bold text-gray-800;
}
h2 {
  @apply text-lg font-semibold text-gray-600;
}
.btn-tools {
  @apply border border-solid border-transparent text-sm uppercase text-white bg-blue-500 !important  px-4 py-2 hover:bg-transparent hover:border-blue-500 hover:text-blue-500 rounded-md cursor-pointer;
}
</style>
